import { Rating, Ranks, RanksNamed, StatParam, HexColor } from "@/types";

export const adrMap: StatParam = {
  insane: 100,
  high: 80,
  medium: 60,
  low: 0,
};

export const winRateMap: StatParam = {
  insane: 0.8,
  high: 0.5,
  medium: 0.4,
  low: 0,
};

export const headshotPercentageMap: StatParam = {
  insane: 0.8,
  high: 0.6,
  medium: 0.4,
  low: 0,
};

export const kdMap: StatParam = {
  insane: 1.2,
  high: 1,
  medium: 0.5,
  low: 0,
};

export const hltvRatingMap: StatParam = {
  insane: 1.6,
  high: 1.1,
  medium: 0.85,
  low: 0.85,
};

export const rankMap: Ranks = {
  S1: 1,
  S2: 2,
  S3: 3,
  S4: 4,
  SE: 5,
  SEM: 6,
  GN1: 7,
  GN2: 8,
  GN3: 9,
  GNM: 10,
  MG1: 11,
  MG2: 12,
  MGE: 13,
  DMG: 14,
  LE: 15,
  LEM: 16,
  SMFC: 17,
  GE: 18,
};

export const namedRankMap: RanksNamed = {
  S1: "Silver I",
  S2: "Silver II",
  S3: "Silver III",
  S4: "Silver IV",
  SE: "Silver Elite",
  SEM: "Silver Elite Master",
  GN1: "Gold Nova I",
  GN2: "Gold Nova II",
  GN3: "Gold Nova III",
  GNM: "Gold Nova Master",
  MG1: "Master Guardian I",
  MG2: "Master Guardian II",
  MGE: "Master Guardian Elite",
  DMG: "Distinguished Master Guardian",
  LE: "Legendary Eagle",
  LEM: "Legendary Eagle Master",
  SMFC: "Supreme Master First Class",
  GE: "The Global Elite",
};

export const ratingsMap: Record<Rating, HexColor> = {
  poor: "#ef4444",
  subpar: "#f97316",
  average: "#d4d4d8",
  good: "#16a34a",
  great: "#84cc16",
};

export const ratingsTextMap: Record<Rating, HexColor> = {
  poor: "#fff",
  subpar: "#fff",
  average: "#000",
  good: "#fff",
  great: "#000",
};
