import { Player } from "@/types";
import { rankMap, namedRankMap } from "@/maps";

export const localCurrency = "USD";

export const currencyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 0,
});

export const numberFormatter = new Intl.NumberFormat("en-US", {
  style: "decimal",
  minimumFractionDigits: 0,
});

export const getRankImageUrl = (rank: Player.MatchMakingRanks): string => {
  return `https://csranks.gg/images/ranks/${rankMap[rank]}.png`;
};

export const getRankTier = (rank: number): string => {
  if (rank >= 30000) {
    return 'tier-6';
  } else if (rank >= 25000) {
    return 'tier-5';
  } else if (rank >= 20000) {
    return 'tier-4';
  } else if (rank >= 15000) {
    return 'tier-3';
  } else if (rank >= 10000) {
    return 'tier-2';
  } else if (rank >= 5000) {
    return 'tier-1';
  } else {
    return 'tier-0';
  }
};

export const getTierLargeText = (rank: number): string => {
  return rank.toString().slice(0, -3);
};

export const getTierSmallText = (rank: number): string => {
  return (rank > 999 ? ',' : '') + rank.toString().slice(-3);
};

export const isPlayerBanned = (player: Player.Base): boolean => {
  return player.banned || player.faceitBanned ? true : false;
};

export const getFormattedNumber = (number?: number): string | null => {
  return number ? numberFormatter.format(Math.ceil(number)) : null;
};

export const getNamedRank = (
  rank: Player.MatchMakingRanks
): Player.MatchMakingRanksNamed => {
  return namedRankMap[rank];
};
