import { APIResponseData } from "./types";

export const mockStatus = `https://steamcommunity.com/id/kus
https://steamcommunity.com/profiles/76561197979545410
76561197988751956
[U:1:93454447]
STEAM_0:0:17649889`;

export const mockResponse: APIResponseData = {
  players: [
    {
      csgoCompWins: 900,
      aliases: [
        "Murcurio",
        "Murcurio_Moth is in Mexico",
        "Murcurio_Grylls",
        "Fan Man",
        "Murcurio @dunxian",
        "Murcurio @DunxLan",
        "The Sparrow Prince",
        "RAZER THUNDERBOLT™ 4 DOCK",
        "Scott’s Refrigerated Logistics",
        "Scott's Refrigerated Logistics",
      ],
      highestRating: 2.29,
      playedSince: "2023-09-28",
      totalHours: 3083,
      highestADR: 149,
      leetifyRatingT: 4.26,
      rating: 1.47,
      achievementsHacked: false,
      hs: 0.36,
      partySize: 4.23,
      firstMatch: "2023-09-28",
      faceitMatches: 233,
      bannedFriendsPercent: 0,
      games: 64,
      rank: 15865,
      accountPrivate: false,
      deaths: 15.17,
      faceitRankHighest: 5,
      csgoRank: "LE",
      bannedFriends: 0,
      leetifyRating: 3.96,
      steamID64: "76561197995565506",
      faceitWinrt: 0.59,
      faceitWins: 138,
      adr: 100,
      friends: 0,
      csgoHighestRank: "LEM",
      clutch: 10.84,
      faceitElo: 1231,
      name: "Murcurio",
      winRate: 0.54,
      faceitName: "Murcurio",
      items: [
        {
          item: "M9 Bayonet",
          price: 1694.8,
          exterior: "Factory New",
          skin: "Fade",
        },
        {
          item: "AK-47",
          price: 651.01,
          exterior: "Factory New",
          skin: "Vulcan",
        },
        {
          item: "Desert Eagle",
          price: 521.2,
          exterior: "Factory New",
          skin: "Blaze",
        },
        {
          item: "AWP",
          stattrak: true,
          price: 210.92,
          exterior: "Field-Tested",
          skin: "Asiimov",
        },
        {
          item: "M4A1-S",
          price: 195.34,
          exterior: "Minimal Wear",
          skin: "Printstream",
        },
      ],
      kills: 20.67,
      leetifyGames: 422,
      img: "https://avatars.steamstatic.com/af81a3c44c153844b158b9b543f1044b243ce8a2_full.jpg",
      aim: 79.39,
      faceitRank: 4,
      highestKills: 36,
      utility: 27.43,
      highestRank: 15865,
      ratings: [
        0.35, 0.35, 0.55, 0.41, 0.34, 0.44, 0.2, 0.68, 0.45, 0.53, 0.59, 0.5,
        0.57, 0.57, 0.45, 0.28, 0.37, 0.88, 0.5, 0.66, 0.27, 0.37, 0.61, 0.53,
        0.42, 0.6, 0.48, 0.53, 0.69, 0.32,
      ],
      positioning: 70.88,
      leetifyRatingCt: 3.65,
      faceitHs: 0.29,
      kd: 1.39,
      value: 3903.69,
      accountCreated: "2008-01-04",
      faceitLevel: 6,
      faceitKd: 1.74,
      avatar:
        "https://avatars.akamai.steamstatic.com/0081f5619d8ab789c70ac3a22b2bc8f71211dd88_full.jpg",
      opening: 6.21,
      lastTwoWeeksHours: 4.9,
      steamID: "STEAM_0:0:17649889",
      ratingRecent: 1.47,
      compWins: 35,
    },
    {
      kills: 15.33,
      leetifyGames: 674,
      img: "https://avatars.steamstatic.com/5f27d7bc162f2aef80d916c811c9fb3eb5b4e8cd_full.jpg",
      csgoCompWins: 784,
      aliases: ["blk-Na7han"],
      highestRating: 2.21,
      aim: 68.08,
      playedSince: "2023-10-05",
      totalHours: 2364.5,
      highestADR: 131,
      leetifyRatingT: 0.65,
      rating: 1.07,
      highestKills: 25,
      achievementsHacked: false,
      utility: 65.17,
      hs: 0.36,
      partySize: 4.5,
      firstMatch: "2023-11-08",
      highestRank: 14994,
      ratings: [
        0.32, 0.32, 0.33, 0.33, 0.56, 0.3, 0.39, 0.69, 0.39, 0.42, 0.3, 0.67,
        0.35, 0.28, 0.46, 0.44, 0.29, 0.45, 0.55, 0.24, 0.43, 0.38, 0.2, 0.35,
        0.41, 0.3, 0.26, 0.28, 0.28, 0.39,
      ],
      positioning: 52.79,
      leetifyRatingCt: -0.55,
      bannedFriendsPercent: 0.08,
      games: 131,
      kd: 0.96,
      rank: 14860,
      value: 1155.71,
      accountPrivate: false,
      deaths: 15.17,
      accountCreated: "2007-03-12",
      csgoRank: "LE",
      bannedFriends: 9,
      leetifyRating: 0.04,
      steamID64: "76561197988751956",
      avatar:
        "https://avatars.akamai.steamstatic.com/08192cce83769a6d90b077fa7c46fa82e93f7d15_full.jpg",
      opening: 0.96,
      adr: 71,
      friends: 104,
      lastTwoWeeksHours: 10.1,
      steamID: "STEAM_0:0:14243114",
      csgoHighestRank: "LE",
      ratingRecent: 1.07,
      clutch: 10.91,
      name: "blk-Na7han",
      winRate: 0.48,
      faceitName: "blkNa7han",
      compWins: 63,
      items: [
        {
          item: "Bowie Knife",
          price: 378.6,
          exterior: "Factory New",
          skin: "Gamma Doppler - Phase 1",
        },
        {
          item: "Driver Gloves",
          price: 177.32,
          exterior: "Battle-Scarred",
          skin: "Imperial Plaid",
        },
        {
          item: "M4A1-S",
          price: 122.96,
          exterior: "Field-Tested",
          skin: "Printstream",
        },
        {
          item: "USP-S",
          price: 42.76,
          exterior: "Minimal Wear",
          skin: "Printstream",
        },
        {
          item: "M4A1-S",
          stattrak: true,
          price: 36.86,
          exterior: "Minimal Wear",
          skin: "Decimator",
        },
      ],
    },
    {
      csgoCompWins: 1257,
      aliases: [
        "dunx",
        "scrumptious peen bro",
        "blatant foreskin",
        "chicken wizard",
        "duncan'd",
        "duncan",
        "knob lord",
        "knoblord",
        "i ❤️ fat hoes",
        "i ❤️ the fat hoes",
      ],
      highestRating: 1.87,
      playedSince: "2023-09-28",
      highestADR: 129,
      leetifyRatingT: 0.97,
      rating: 1.17,
      achievementsHacked: false,
      hs: 0.42,
      partySize: 4.6,
      firstMatch: "2023-09-28",
      faceitMatches: 88,
      bannedFriendsPercent: 0,
      games: 129,
      rank: 15708,
      accountPrivate: false,
      deaths: 15.03,
      faceitRankHighest: 6,
      csgoRank: "LEM",
      bannedFriends: 0,
      leetifyRating: 0.45,
      steamID64: "76561197979545410",
      faceitWinrt: 0.45,
      faceitWins: 40,
      adr: 84,
      friends: 0,
      csgoHighestRank: "LEM",
      clutch: 7.33,
      faceitElo: 1346,
      name: "dunx",
      winRate: 0.58,
      faceitName: "dunx",
      items: [
        {
          item: "Talon Knife",
          price: 1054.9,
          exterior: "Factory New",
          skin: "Doppler - Phase 2",
        },
        {
          item: "Driver Gloves",
          price: 107.59,
          exterior: "Minimal Wear",
          skin: "Queen Jaguar",
        },
        {
          item: "Cmdr. Frank 'Wet Sox' Baroud",
          price: 27.67,
          skin: "SEAL Frogman",
        },
        {
          item: "USP-S",
          stattrak: true,
          price: 20.68,
          exterior: "Minimal Wear",
          skin: "Stainless",
        },
        {
          item: "PP-Bizon",
          price: 15.65,
          exterior: "Battle-Scarred",
          skin: "Rust Coat",
        },
      ],
      kills: 15.83,
      leetifyGames: 716,
      img: "https://avatars.steamstatic.com/711661b6281b28783163c96a37469f6d1c596500_full.jpg",
      aim: 61.03,
      faceitRank: 5,
      highestKills: 27,
      utility: 77.04,
      highestRank: 15708,
      ratings: [
        0.25, 0.3, 0.29, 0.46, 0.45, 0.12, 0.43, 0.37, 0.43, 0.38, 0.21, 0.42,
        0.4, 0.27, 0.47, 0.4, 0.41, 0.43, 0.48, 0.41, 0.31, 0.38, 0.35, 0.47,
        0.4, 0.5, 0.49, 0.44, 0.39, 0.4,
      ],
      positioning: 50.8,
      leetifyRatingCt: -0.02,
      faceitHs: 0.35,
      kd: 1.08,
      value: 1378.17,
      accountCreated: "2005-12-04",
      faceitLevel: 6,
      faceitKd: 1.04,
      avatar:
        "https://avatars.akamai.steamstatic.com/9b6f3c07721676ef0e08885ca00c61d6e505d99b_full.jpg",
      opening: -0.55,
      lastTwoWeeksHours: 0,
      steamID: "STEAM_0:0:9639841",
      ratingRecent: 1.17,
      compWins: 75,
    },
    {
      csgoCompWins: 862,
      highestRating: 2.35,
      playedSince: "2023-09-27",
      highestADR: 147,
      leetifyRatingT: 3.75,
      rating: 1.41,
      achievementsHacked: false,
      hs: 0.36,
      partySize: 4.67,
      firstMatch: "2023-09-28",
      faceitMatches: 15,
      bannedFriendsPercent: 0,
      games: 114,
      rank: 14734,
      accountPrivate: false,
      deaths: 14.63,
      faceitRankHighest: 4,
      csgoRank: "LE",
      bannedFriends: 0,
      leetifyRating: 4.32,
      steamID64: "76561197960377828",
      faceitWinrt: 0.47,
      faceitWins: 7,
      adr: 91,
      friends: 0,
      csgoHighestRank: "GE",
      clutch: 18.19,
      faceitElo: 984,
      name: "Kus",
      winRate: 0.5,
      faceitName: "Kus_",
      items: [
        {
          item: "AK-47",
          price: 3597.38,
          exterior: "Factory New",
          skin: "Gold Arabesque",
        },
        {
          item: "Karambit",
          stattrak: true,
          price: 1117.71,
          exterior: "Factory New",
          skin: "Doppler - Phase 4",
        },
        {
          item: "Specialist Gloves",
          price: 1006.06,
          exterior: "Minimal Wear",
          skin: "Fade",
        },
        {
          item: "USP-S",
          stattrak: true,
          price: 424.99,
          exterior: "Factory New",
          skin: "Kill Confirmed",
        },
        {
          item: "M4A1-S",
          stattrak: true,
          price: 321.1,
          exterior: "Factory New",
          skin: "Hyper Beast",
        },
      ],
      kills: 18.87,
      leetifyGames: 667,
      img: "https://avatars.steamstatic.com/791b47aa5d4c54675357b03d1d85c9a013c531f1_full.jpg",
      aim: 65.54,
      faceitRank: 4,
      highestKills: 31,
      utility: 42.07,
      highestRank: 14829,
      ratings: [
        0.57, 0.55, 0.59, 0.46, 0.48, 0.51, 0.46, 0.38, 0.35, 0.41, 0.11, 0.37,
        0.49, 0.75, 0.43, 0.42, 0.6, 0.62, 0.5, 0.51, 0.6, 0.37, 0.39, 0.5, 0.4,
        0.47, 0.57, 0.61, 0.43, 0.35,
      ],
      positioning: 52.39,
      leetifyRatingCt: 4.85,
      faceitHs: 0.298,
      kd: 1.21,
      value: 8506.49,
      accountCreated: "2003-09-12",
      faceitLevel: 4,
      faceitKd: 0.95,
      avatar:
        "https://avatars.akamai.steamstatic.com/bb1402f7def351ca26553a1b4a8b6f9e118ec52a_full.jpg",
      opening: 1.5,
      lastTwoWeeksHours: 0,
      steamID: "STEAM_0:0:56050",
      ratingRecent: 1.41,
      compWins: 57,
    },
    {
      kills: 19.2,
      leetifyGames: 828,
      img: "https://avatars.steamstatic.com/8e3da3adcf6ed612dc87d35f2a8c21972204c625_full.jpg",
      csgoCompWins: 1277,
      highestRating: 2.2,
      aim: 75.49,
      playedSince: "2023-09-28",
      totalHours: 3679.9,
      highestADR: 132,
      leetifyRatingT: 1.61,
      rating: 1.29,
      highestKills: 31,
      achievementsHacked: false,
      utility: 48.8,
      hs: 0.49,
      partySize: 4.37,
      firstMatch: "2023-09-28",
      highestRank: 15587,
      ratings: [
        0.42, 0.49, 0.33, 0.38, 0.28, 0.35, 0.41, 0.39, 0.34, 0.87, 0.29, 0.34,
        0.46, 0.37, 0.58, 0.29, 0.42, 0.3, 0.43, 0.86, 0.5, 0.55, 0.27, 0.4,
        0.29, 0.67, 0.31, 0.49, 0.31, 0.39,
      ],
      positioning: 60.64,
      leetifyRatingCt: 1.63,
      bannedFriendsPercent: 0,
      games: 128,
      kd: 1.16,
      rank: 15587,
      value: 2439.65,
      accountPrivate: false,
      deaths: 15.57,
      accountCreated: "2011-12-02",
      csgoRank: "LEM",
      bannedFriends: 0,
      leetifyRating: 1.62,
      steamID64: "76561198053720175",
      avatar:
        "https://avatars.akamai.steamstatic.com/8e3da3adcf6ed612dc87d35f2a8c21972204c625_full.jpg",
      opening: 2.69,
      adr: 85,
      friends: 23,
      lastTwoWeeksHours: 4.5,
      steamID: "STEAM_0:1:46727223",
      csgoHighestRank: "LEM",
      ratingRecent: 1.29,
      clutch: 13.83,
      name: "KegZ",
      winRate: 0.53,
      faceitName: "Kegs0r",
      compWins: 68,
      items: [
        {
          item: "Skeleton Knife",
          price: 1512.45,
          exterior: "Factory New",
          skin: "Fade",
        },
        {
          item: "Hand Wraps",
          price: 663.72,
          exterior: "Minimal Wear",
          skin: "Cobalt Skulls",
        },
        {
          item: "M4A1-S",
          price: 113.88,
          exterior: "Factory New",
          skin: "Hyper Beast",
        },
        {
          item: "AK-47",
          price: 62.74,
          exterior: "Factory New",
          skin: "Nightwish",
        },
        {
          item: "D Squadron Officer",
          price: 19.49,
          skin: "NZSAS",
        },
      ],
    },
  ],
  lobbies: {},
};
