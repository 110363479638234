import axios from "axios";
import type { Player, APIResponse } from "@/types";

const url = "https://australia-southeast1-cs-ranks.cloudfunctions.net/api";

export async function getMatch(
  status: string,
  offline?: boolean
): Promise<APIResponse> {
  return await axios.post(
    offline ? `${url}/offline` : url,
    { status },
    { headers: { "Content-Type": "application/x-www-form-urlencoded" } }
  );
}
